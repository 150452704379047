/* Global styles */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    padding: 20px 30px;
    margin: 0; 
    background: black;
    color: white;
}
@font-face {
    font-family: "SF-Pro";
    src: url("./data/font/SF-Pro-Display-Regular.otf") format("opentype"); /* Confirm path and format */
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "SF-Pro", sans-serif; /* Use the custom font */
    padding: 20px 30px;
    margin: 0;
    background: black;
    color: white;
}
@media (min-width: 1024px) {
    body {
        max-width: 1440px;
        margin: 0 auto; 
        
    }
}

@media (min-width: 640px) {
    body {
        padding: 20px 60px;
    }
}

:root {
    --heading-color: #282938;
    --bg-shade: #f9f4f4;
    --blue-shade: #CCE8FF;
    --green-shade: #CCFFDA;
    --pink-shade: #FFBDB9;
    --darkblue: #1c1e53;
    --black: #181B2E;
    --white: #ffffff;
    --bg-gradient: linear-gradient(90deg, #ffffff 0.41%, #D9D9D9 50.29%, #FFF400 96.57%, #9D5382 100.08%);
}

.bg-custom-gradient {
    background: var(--bg-gradient);
    -webkit-background-clip: text; /* Apply background as text fill */
    -webkit-text-fill-color: transparent; /* Hide text color */
}

/* H1 Heading */
h1 {
    font-size: 10vw;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    background-clip: text; /* Apply background as text fill */
    -webkit-background-clip: text; /* For Safari */
    color: white; /* Hide text color */
}

/* H2 Heading */
h2 {
    font-size: 5vw;
    font-weight: 500;
    line-height: 140%;
}

/* H3 Heading */
h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 140%;
}
h5 {
    font-size: larger;
}
/* Body text styles */
p {
    font-size: medium;
    font-style: normal;
    font-weight: 400;
    color: #d7d3d3;
}

.inputField {
    border-radius: 15px;
    border: 1.8px solid rgba(255, 255, 255, 0.50);
    background: rgba(255, 255, 255, 0.10);
    padding: 15px 30px;
    align-items: center;
    
} 

textarea {
    border-radius: 15px;
    border: 1.8px solid rgba(255, 255, 255, 0.50);
    background: rgba(255, 255, 255, 0.10);
    width: 100%;
    padding: 0 30px;
    padding: 15px ;
    align-items: flex-start;
}

.buttonPrimary {
    border-radius: 15px;
    border: 1.8px solid rgba(255, 255, 255, 0.50);
    background: rgba(255, 255, 255, 0.10);
    padding: 15px 30px;
    align-items: center;
    background: #ffffff;
    color: black;
    width: 100%;
    
    &:hover {
        background-color: white;
        transition-duration: 1.5s;
        transition: ease-in;
    }
}

.buttonSecondary {
    border: 1px solid gray;
    padding: 8px;
    border-radius: 100px;
}

.borderContainer {
    border: 1px solid gray;
    padding: 6px;
    border-radius: 10px;
}
@keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .animate-shine-infinite {
    animation: shine 2s infinite;
  }

  .personalStatement:hover {
    cursor: url('../src/Pages/Home/cursor.jsx'), auto; /* Replace 'path/to/custom-cursor.png' with the path to your custom cursor image */
}

